:root {
  --primary: rgb(0, 26, 76);
  --secondary: #51f8a8;
  --primaryLight: #001a4c40;
  --checkColor: #fff;
  --textSecondaryBtnColor: #fff;
  --bgSecondaryBtnColor: rgb(0, 26, 76);
  --textInSecondaryColor: rgb(0, 26, 76);
  --warning: #ff7f00;
  --danger: crimson;
  --toggleSectionIconColor: rgb(0, 26, 76);
  --magnifyingGlassColor: #51f8a8;
  --envelopeColor: #51f8a8;
}
